import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/home.vue')
},
{
  path: '/home',
  name: 'home',
  component: () => import('../views/home.vue')
},
{
  path: '/dw',
  name: 'dw',
  component: () => import('../views/dw.vue')
}]
const router = new VueRouter({
  routes
})

export default router